import React from "react";
import jobs from "../json/jobs.json";

function Experience() {
  function clickHandler(e) {
    e.preventDefault();
    window.open(e.target.id, "_blank");
  }

  function isInViewport(element) {
    var rect = element.getBoundingClientRect(); // Get the bounding rectangle of the element
    return (
      rect.top >= 0 && // Check if the top of the element is at least partially visible
      rect.left >= 0 && // Check if the left side of the element is at least partially visible
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) && // Check if the bottom of the element is at most partially visible
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) // Check if the right side of the element is at most partially visible
    );
  }

  var allJobs = document.getElementsByClassName("job-card");

  window.addEventListener("scroll", function () {
    for (let i = 0; i < allJobs.length; i++) {
      if (isInViewport(allJobs[i])) {
        allJobs[i].classList.add("animate-school");
      }
    }
  });

  return (
    <div className="wrapper">
      <h2>Starfsreynsla</h2>
      <div className="jobs">
        {jobs.map((job) => (
          <div
            className={"job-card job-card-" + job.number}
            key={job.title}
            onClick={clickHandler}
            id={job.link}
          >
            <h3 onClick={clickHandler} id={job.link}>
              {job.title}
            </h3>
            <h4 onClick={clickHandler} id={job.link}>
              {job.time}
            </h4>
            <p onClick={clickHandler} id={job.link}>
              {job.desc}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;
