import React from "react";
import subjects from "../json/subjects.json";

function School() {
  function clickLink(e) {
    e.preventDefault();
    window.open(e.target.id, "_blank");
  }

  function isInViewport(element) {
    var rect = element.getBoundingClientRect(); // Get the bounding rectangle of the element
    return (
      rect.top >= 0 && // Check if the top of the element is at least partially visible
      rect.left >= 0 && // Check if the left side of the element is at least partially visible
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) && // Check if the bottom of the element is at most partially visible
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) // Check if the right side of the element is at most partially visible
    );
  }

  window.addEventListener("scroll", function () {
    const year = document.getElementsByClassName("year");
    const listItems = this.document.getElementsByClassName("listItem");
    for (let i = 0; i < year.length; i++) {
      if (isInViewport(listItems[i])) {
        year[i].classList.add("animate-school");
      }
    }
  });

  return (
    <div className="wrapper" id="school">
      <h2>Hugbúnaðarverkfræði BS, HÍ</h2>
      <div className="school">
        {subjects.map((semester) => (
          <div key={semester.number} className={"year year" + semester.number}>
            <h3>{semester.title}</h3>
            <div className={"s-card s-card-" + semester.number}>
              <ul>
                {semester.subjects.map((subject) => (
                  <li
                    className="listItem"
                    key={subject.name}
                    onClick={clickLink}
                    id={subject.link}
                  >
                    <div
                      onClick={clickLink}
                      id={subject.link}
                      className="subject-name"
                    >
                      {subject.name}
                    </div>
                    <div
                      onClick={clickLink}
                      id={subject.link}
                      className="subject-grade"
                    >
                      {subject.grade}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {semester.number === "2" ? (
              <div className="s-card s-card-4">
                <h4>Skiptinám</h4>
                <p>
                  Haustið 2021 fór ég í skiptinám til Kaupmannahafnar í
                  Københavns Universitet. Þar lagði ég áherslu á machine
                  learning og gagnavinnslu í áfangavali og fékk góðan grunn í
                  því. Ég lærði mikið á dvölinni bæði um viðfangsefni
                  námskeiðanna en einnig hvernig maður bjargar sér í ýmsum
                  aðstæðum.
                </p>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default School;
