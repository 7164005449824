import React from "react";
import front from "../photos/foodjournal/fj-front.png";
import weekly from "../photos/foodjournal/fj-weekly.png";

function FoodJournal() {
  function photoHandler(e) {
    e.preventDefault();
    window.open("https://foodjournal-production.up.railway.app/", "_blank");
  }

  function isInViewport(element) {
    var rect = element.getBoundingClientRect(); // Get the bounding rectangle of the element
    return (
      rect.top >= 0 && // Check if the top of the element is at least partially visible
      rect.left >= 0 && // Check if the left side of the element is at least partially visible
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) && // Check if the bottom of the element is at most partially visible
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) // Check if the right side of the element is at most partially visible
    );
  }

  window.addEventListener("scroll", function () {
    const photos = document.getElementsByClassName("foodjournalHeader");
    const fojo = document.getElementsByClassName("foodjournal")[0];
    if (isInViewport(photos[0])) {
      // If the front bar is scrolled more than halfway into view, add the CSS class to trigger the animation
      fojo.classList.add("animate-school");
    }
  });

  return (
    <div className="wrapper-2" id="foodjournal">
      <div className="foodjournal">
        <div className="foodjournal-content">
          <div className="foodjournal-text">
            <h2 className="foodjournalHeader">FoodJournal</h2>
            <p>
              Í áfanganum Hugbúnaðarverkefni 1, er verkefnið yfir alla önnina að
              byggja full stack vefsíðu frá grunni. Við gerðum vefsíðu með Java
              spring bakenda og HTML og Thymeleaf framenda. Ég og hópfélagar
              mínir gerðum verkefnið FoodJournal sem er uppskriftagagnagrunnur
              með dagatals virkni. Það er, að notandinn setur inn sínar
              uppskriftir í gagnagrunninn, í gefnu sniði sem við gerðum sem
              hefur hashmap fyrir innihaldsefni og magn af þeim í uppskrift,
              lýsingu á hvernig skal matreiða gefinn rétt og boolean breytur
              eins og vegan eða gluten free svo eitthvað sé nefnt. Síðan getur
              notandinn sett þessar uppskriftir á daga í dagatalinu til að plana
              innkaup og matarneyslu. Síðan er virkni eins og import recipe sem
              leyfir notendum að deila uppskriftum með öðrum notendum og
              innkaupa listi sem tekur öll innihaldsefni í rétt sem er búið að
              setja í dagatalið og gerir innkaupalista með þeim. Núna erum við í
              Hugbúnaðarverkefni 2, þar sem að við erum að vinna í Android appi
              fyrir verkefnið og laga það sem betur hefði mátt fara.
            </p>
          </div>
          <div className="foodjournal-photos">
            <img
              src={front}
              alt="foodjournal"
              className="fojoImg"
              onClick={photoHandler}
            />
            <img
              src={weekly}
              alt="foodjournal"
              className="fojoImg"
              onClick={photoHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodJournal;
