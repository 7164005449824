import "./styles.css";
import Header from "./components/Header";
import School from "./components/School";
import Experience from "./components/Experience";
import FoodJournal from "./components/Foodjournal";
import Skills from "./components/Skills";
import React from "react";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <School />
        <FoodJournal />
        <Experience />
        <Skills />
      </main>
      <footer></footer>
    </div>
  );
}

export default App;
