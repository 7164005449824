import ottar from "../photos/ottar.jpg";
import React from "react";
function Header() {
  function arrowHandler(event) {
    event.preventDefault();
    const targetDiv = document.getElementById("school");
    targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <header className="App-header">
      <div className="header-content">
        <img className="front-photo" alt="mynd af Óttari" src={ottar}></img>
        <h1>Óttar Þór</h1>
        <p>
          Óttar heiti ég og er nemi í hugbúnaðarverkfræði við Háskóla Íslands.
          Ég hef aldrei unnið við það sem ég er að læra og mig langar að breyta
          því. Ég er með öfluga grunnreynslu á mjög breiðu sviði í
          hugbúnaðarþróun og fór m.a. í skiptinám til Kaupmannahafnar þar sem ég
          einblíndi á machine learning og eyði í augnablikinu mestum tíma í
          vefþjónustur og vefforritun. Þrátt fyrir að hafa ekki unnið í
          hugbúnaði, hef ég unnið mörg mismunandi störf, sem að nýtast mér
          klárlega, þar sem ég er ekki ókunnugur því að leggja hart að mér og að
          vinna í fjölbreyttu teymi. Ég er mjög góður að læra nýja hluti hratt
          og aðlagast vel. Ég er einnig mjög félagslyndur og hress náungi
          almennt.
        </p>
        <div className="socials">
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 5.324V15.5A1.5 1.5 0 001.5 17h17a1.5 1.5 0 001.5-1.5V5.324l-9.496 5.54a1 1 0 01-1.008 0L0 5.324z"
              fill="#5C5F62"
            />
            <path
              d="M19.443 3.334A1.494 1.494 0 0018.5 3h-17c-.357 0-.686.125-.943.334L10 8.842l9.443-5.508z"
              fill="#5C5F62"
            />
          </svg>
          <p>ottarthor@gmail.com</p>
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.876 6.976l-.534-2.67A1.5 1.5 0 005.871 3.1H2.638c-.86 0-1.576.727-1.537 1.586.461 10.161 5.499 14.025 14.415 14.413.859.037 1.584-.676 1.584-1.535v-3.235a1.5 1.5 0 00-1.206-1.471l-2.67-.534a1.5 1.5 0 00-1.636.8l-.488.975c-2 0-5-3-5-5l.975-.488c.606-.302.934-.972.801-1.635z"
              fill="#5C5F62"
            />
          </svg>
          <p>6981830</p>
        </div>
      </div>
      <div className="arrowDown" onClick={arrowHandler}>
        <svg
          fill="#5C5F62"
          width="40px"
          height="40px"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          id="arrowDown"
        >
          <path d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"></path>
        </svg>
      </div>
    </header>
  );
}

export default Header;
