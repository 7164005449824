import React from "react";
import skills from "../json/skills.json";

function Skills() {
  function arrowHandler(event) {
    event.preventDefault();
    const targetDiv = document.getElementsByClassName("App-header")[0];
    targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  function isInViewport(element) {
    var rect = element.getBoundingClientRect(); // Get the bounding rectangle of the element
    return (
      rect.top >= 0 && // Check if the top of the element is at least partially visible
      rect.left >= 0 && // Check if the left side of the element is at least partially visible
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) && // Check if the bottom of the element is at most partially visible
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) // Check if the right side of the element is at most partially visible
    );
  }

  var skillBars = document.getElementsByClassName("front-bar ");

  window.addEventListener("scroll", function () {
    for (let i = 0; i < skillBars.length; i++) {
      let front = skillBars[i];
      if (isInViewport(front)) {
        front.classList.add("animate-bar");
        front.style.width = skills[i].level;
      }
    }
  });

  return (
    <div className="wrapper-2">
      <h2>Kunnátta</h2>
      <div className="skills">
        {skills.map((skill) => (
          <div className={"skill-bar " + skill.id} key={skill.id}>
            <h3>{skill.skill}</h3>
            <div className="background-bar">
              <div className={"front-bar "} id={"skill" + skill.id}></div>
            </div>
          </div>
        ))}
      </div>
      <div className="arrowUp" onClick={arrowHandler}>
        <svg
          fill="#5C5F62"
          width="40px"
          height="40px"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          id="arrowUp"
        >
          <path d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"></path>
        </svg>
      </div>
    </div>
  );
}

export default Skills;
